import React, { useEffect, useRef, useState } from "react"
import { navigate } from '@reach/router'
import swal from 'sweetalert2'
import Loader from 'react-loader-spinner'

import  { ContainerSection } from './Section'
import ImageButton from './ImageButton'
import BibleVerseImage from '../assets/images/home/bible-verse.jpg'
import PrayerRequestImage from '../assets/images/prayer-request/2.jpg'
import WelcomeImage from '../assets/images/welcome/1.png'
import CovidImage from '../assets/images/home/covid.jpg'
import FindUsImage from '../assets/images/find-us/1.png'
import YouthMinistryImage from '../assets/images/home/young-adults.jpg'
import EventsImage from '../assets/images/home/events-white-light.jpg'




import AlertImage from '../assets/images/alert.png'
import Modal from "./Modal"
import Slideshow from "./Slideshow"


import { getVerseOfTheDay, sendPrayerRequest } from "../lib/API"



export default () => {
    const prayerRequestModalRef = useRef()
    const welcomeVideoModalRef = useRef()
    const covidModalRef = useRef()

    const [ verseOfTheDay, setVerseOfTheDay ] = useState( "" )
    const [ verseOfTheDayModal, setVerseOfTheDayModal ] = useState( false )
    const [ prayerRequestModal, setPrayerRequestModal ] = useState( false )
    const [ covidModal, setCovidModal ] = useState( false )
    const [ welcomeVideoModalOpen, setWelcomeVideoModalOpen ] = useState( false )

    const [ name, setName ] = useState( '' )
    const [ request, setRequest ] = useState( '' )
    const [ loading, setLoading ] = useState( false )

    const send = async e => {
        e.preventDefault()
        e.stopPropagation()
        setLoading( true )
        const data = { name, request }
        const res = await sendPrayerRequest( data )
        setLoading( false )
        
        if( res.sent ) {
            swal.fire( 'Success', 'Request sent successfully', 'success' ).then( () => {
                setName( '' )
                setRequest( '' )
                setPrayerRequestModal( false )
            } )
            
        }
        else{
            swal.fire( 'Could not send request', 'Please try again later', 'error' )
        }
    }

    const changeValue = ( e, handler ) => handler( e.target.value ) 

    const closeModals = () => {
        const stopVideo = function ( element ) {
            if( !element ) return 
            const iframe = element.querySelector( 'iframe' )
            const video = element.querySelector( 'video' )
            if ( iframe ) {
                var iframeSrc = iframe.src
                iframe.src = iframeSrc
            }
            if ( video ) {
                video.pause()
            }
        }

        stopVideo( welcomeVideoModalRef.current )

        setPrayerRequestModal( false )
        setVerseOfTheDayModal( false )
        setCovidModal( false )
        setWelcomeVideoModalOpen( false )
    }

    useEffect( () => {

        const loadVerseOfTheDay = async () => {
            const data = await getVerseOfTheDay()
            // console.log( data )
            if( !data ){
                setVerseOfTheDay( { title: "Currently Unavailable", text: "" } )
                return
            }
            const votdData = data && data.channel[0].item[0]
            
            if( !votdData ) return

            const votdTitle = votdData["title"][0]
            // const votdText = votdData && votdData["content:encoded"][0].replace(/<\/?[^>]+(>|$)/g, "").split("quo;")[1].split("[Doxology] ")[1].slice(0,-3)
            const votdText = votdData && votdData["content:encoded"][0].replace( /<\/?[^>]+(>|$)/g, "" ).split( "quo;" )[1].slice( 0,-3 )
            setVerseOfTheDay( { text: votdText, title: votdTitle } )
        }

        loadVerseOfTheDay()
        
    }, [] )
   

    return (
        <div className="main-modal-buttons" style={{ textAlign : "center" }}>
            <Modal ref={welcomeVideoModalRef} className="welcome-video" show={welcomeVideoModalOpen} handleClose={closeModals}>
                
                <iframe style={{ width: "100%", height: "60vh", display: 'block' }} src="https://www.youtube.com/embed/z_e6ijWmMbg" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Modal>

            <Modal ref={covidModalRef} className="covid-modal" show={covidModal} handleClose={closeModals}>
                <header className="major" style={{ textAlign: "center" }}><h2>COVID-19 Notice</h2></header>
                <img className="image fit" src={AlertImage} style={{ width: 50, height: 50, margin: "0 auto", marginBottom: 20 }} />
                <div className="votd-content" style={{ textAlign: "center" }}>
                    <p>
                    Due to the stay at home directive by the Government and recommendation by the Baptist Union of Great Britain, 
                    we have suspended all our weekly activities that takes place in our building until further notice.
                    </p>
                </div>
            </Modal>
            
            {verseOfTheDay && <Modal className="votd" show={verseOfTheDayModal} handleClose={closeModals}>
                <header className="major" style={{ textAlign: "center" }}><h2>Verse of the Day</h2></header>
                <div className="votd-content" style={{ textAlign: "center" }}>
                    <h3>{verseOfTheDay.title}</h3>
                    <p className="votd-text">{verseOfTheDay.text}</p>
                </div>
            </Modal>}


            <Modal ref={prayerRequestModalRef} className="prayer-request" show={prayerRequestModal} handleClose={closeModals}>
                <h2 className="major">Submit Prayer Request</h2>
                <form className="login-form" method="POST" onSubmit={send}>
                    <input className="form-input" type="text" name="name" placeholder="Full Name*" value={name} onChange={e => setName( e.target.value )} required /><br/>
                    <textarea rows={3} className="form-input" style={{ resize: "none" }} placeholder="Request*" value={request} onChange={e => setRequest( e.target.value )} required/>

                    <br />
                    {!loading ? <input type="submit" value="Submit" className="button special" /> :
                    <Loader
                        type="Puff"
                        color="#00BFFF"
                        height={40}
                        width={50}
                    />
                    }
                </form>
            </Modal>


            <div id="welcome-buttons" className="padded">
                {/* <Slideshow images={[ EventsImage ]} tint={0} />          */}

                <div className="main-page-button" onBlur={() => setWelcomeVideoModalOpen( false )} onClick={() => setWelcomeVideoModalOpen( true )}>
                    <ImageButton label="Welcome Video" image={WelcomeImage} />
                </div>
            
                <div className="main-page-button" onBlur={() => setVerseOfTheDayModal( false )} onClick={() => setVerseOfTheDayModal( true )}>
                    <ImageButton label="Verse of the Day" image={BibleVerseImage} />
                </div>

            
                <div className="main-page-button" onBlur={() => setPrayerRequestModal( false )} onClick={() => setPrayerRequestModal( true )}>
                    <ImageButton label="Prayer Requests" image={PrayerRequestImage} />
                </div>

                <div className="main-page-button" onBlur={() => setPrayerRequestModal( false )}  onClick={() => navigate( '/contact/#map' )}>
                    <ImageButton label="Find Us" image={FindUsImage} />
                </div>

                <div className="main-page-button" onBlur={() => setPrayerRequestModal( false )}  onClick={() => navigate( '/church-life/#young-adults' )}>
                    <ImageButton label="Young People's Ministry" image={YouthMinistryImage} />
                </div>


                    
                        {/* <div className="main-page-button" onBlur={() => setCovidModal( false )} onClick={() => setCovidModal( true )}>
                            <ImageButton label="COVID-19" image={CovidImage} />
                        </div> */}

            </div>

        </div>
    )
}
